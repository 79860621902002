import axios from "axios";
const path_prefix = "https://thrips-ui.hsnl.tw/api/"; // for static folder(server env)
//const path_prefix = "http://localhost:5000/"; // flask local host
// const path_prefix = "http://192.168.50.143:8090/"; // flask local host
export const getJWT = async (account) => {
    const path = path_prefix + "getJWT";
    return await axios
        .get(path, {
            params: {
                account: account
            },
        })
        .then((res) => {
            return {
                uuid: res.data.uuid,
                token: res.data.token
            }
            
        })
        .catch((error) => {
            console.error(error);
        });
};

export const getAreaID = async (token,uuid) => {
    const path = path_prefix + "getAreaID";
    return await axios
        .get(path, {
            params: {
                token: token,
                ownerID: uuid
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.error(error);
        });
};
export const getGroupID = async (token, areaID) => {
    const path = path_prefix + "getGroupID";
    return await axios
        .get(path, {
            params: {
                token: token,
                areaID: areaID
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.error(error);
        });
};
export const getAllSensor = async (token, groupID) => {
    const path = path_prefix + "getAllSensor";
    if(Object.values(groupID).length <= 0) return [];

    return await axios
        .get(path, {
            params: {
                token: token,
                groupID: Object.values(groupID)[0],
            },
        })
        .then((res) => {
            if(typeof res.data === "string") {
                return []
            }
            let data = {...res.data};
            delete data["電池電壓"];
            return data;
        })
        .catch((error) => {
            console.error(error);
        });
};

export const getTimeIntervalData = async (s_id, s_type, timeInterval,token,selectPreset,timeData) => {
    let path = path_prefix + "getTimeIntervalData";
    let begin_date = timeInterval.value[0].split("-");
    let end_date =
        timeInterval.value[timeInterval.value.length - 1].split("-");
    await axios
        .get(path, {
            params: {
                token: token.value,
                sensorType: s_type,
                account: selectPreset.value.account,
                // sensorID: s_id,
                begin_year: begin_date[0], //"2022",
                begin_month: begin_date[1], //"04",
                begin_day: begin_date[2], //"22",
                end_year: end_date[0], //"2022",
                end_month: end_date[1], // "04",
                end_day: end_date[2], // "22",
            },
        })
        .then((res) => {
            timeData.value[s_type] = res.data;
            return res.data;
        })
        .catch((error) => {
            console.error(error);
        });
};