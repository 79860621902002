export const formatDate = (date) => {
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear();
    if (dd < 10) {
        dd = "0" + dd;
    }
    if (mm < 10) {
        mm = "0" + mm;
    }
    date = yyyy + "-" + mm + "-" + dd;
    return date;
};
export const dpFormat = (dates) => {
    let f = [];
    for (let d in dates) {
        f.push(formatDate(dates[d]));
    }
    f = f.join(" to ");
    return f;
};
export const setLastDays = (num, selectDate) => {
    var result = [];
    for (var i = num - 1; i >= 0; i--) {
        var d = new Date();
        d.setDate(d.getDate() - i);
        // if current hours is after 14:00
        if (!(d.getHours() < 14 && i == 0)) {
            result.push(formatDate(d));
            selectDate.value = [
                new Date(new Date().setDate(new Date().getDate() - (num - 1))),
                new Date(),
            ];
        } // if current hours is before 14:00
        else {
            selectDate.value = [
                new Date(new Date().setDate(new Date().getDate() - (num - 1))),
                new Date(new Date(new Date().setDate(new Date().getDate() - 1))),
            ];
        }
    }
    return result;
};