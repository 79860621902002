<template>
  <div class="container">
    <Menubar >
      <template #start>
        <div class="p-mx-3">
          <router-link to="/">
            <svg
              class=""
              xmlns="http://www.w3.org/2000/svg"
              width="100.792"
              height="52.891"
              viewBox="0 0 180.792 52.891"
            >
              <text
                id="ThripsUI"
                transform="translate(-0.094 42.945)"
                fill="deepskyblue"
                font-size="40"
                font-family="-apple-system,
                  BlinkMacSystemFont,
                  Segoe UI,Roboto,
                  Helvetica,
                  Arial,sans-serif,
                  Apple Color Emoji,
                  Segoe UI Emoji,
                  Segoe UI Symbol"
                font-weight="700"
                letter-spacing="0.05em"
              >
                <tspan x="0" y="0">ThripsUI</tspan>
              </text>
            </svg>
          </router-link>
        </div>
      </template>
      <template #end >
        <!-- <Calendar icon="pi pi-calendar" showIcon="true" touchUI="true" selectionMode="range" :manualInput="false"/> -->
        <div class="flex align-items-center justify-content-left">
          <Dropdown
            class="ml-3"
            v-model="selectedPreset"
            :options="presets"
            optionLabel="label"
            optionGroupLabel="label"
            optionGroupChildren="items"
            placeholder="LianShing_outside-2"
            @change="changePresetSelect"
          />
        </div>
      </template>
    </Menubar>
    <History @emitPreset="changePresetList" ref="refHistory" />
  </div>
</template>
<script>
import { defineComponent, ref } from "vue";
import History from "../views/ConsolePage/History.vue";
import Menubar from "primevue/menubar";
import Dropdown from "primevue/dropdown";
// import Calendar from 'primevue/calendar';

export default defineComponent({
  name: "console",
  data() {
    return {
      presets: [],
      selectedPreset: "LianShing_outside-1",
      // {
      //   icon: "pi pi-fw pi-home",
      //   label: "薊馬辨識",
      //   to: '/',
      //   command: () => {
      //     this.state = "home";
      //   },
      // },
    };
  },
  components: {
    Menubar,
    History,
    Dropdown,
    // Calendar,
  },
  methods: {
    to(path) {
      console.log("PATH:");
      console.log(path);
      // this.$router.push(path);
    },
    changePresetList(value) {
      this.presets = value;
    },
  },
  setup() {
    const state = ref("");
    const refHistory = ref();
    state.value = "history";
    const changePresetSelect = (target) => {
      refHistory.value?.onPresetSelect(target.value);
    };
    return {
      state,
      refHistory,
      changePresetSelect,
    };
  },
});
</script>
<style lang="scss" scoped>
.p-dropdown {
  width: 10rem;
}
::v-deep(.p-menubar-end) {
 margin-left: 0 !important;
 
}
</style>

<style>
.p-inputtext .p-component {
  display: none;
}
.p-menubar-button{
  display: none !important;
}
</style>

