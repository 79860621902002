export const getTwoAxisChartOptions = (title) => {
    return {
        responsive: true,
        //maintainAspectRatio: false,
        elements: {
            point: {
                radius: 1,
            },
        },
        interaction: {
            mode: "index",
            intersect: false,
        },
        plugins: {
            title: {
                display: true,
                text: title,
            },
            legend: {
                // onClick: (e, legendItem, legend) => {
                //     console.log(e,legendItem,legend);
                // },
            }
        },
        scales: {
            x: {
                type: "time",
                time: {
                    unit: "day",
                },
            },
            l1: {
                type: "linear",
                display: true,
                position: "left",
            },
            r1: {
                type: "linear",
                display: true,
                position: "right",

                grid: {
                    drawOnChartArea: false, // only want the grid lines for one axis to show up
                },
            },
        },
    };
};

export const getSingleAxisChartOptions = (title) => {
    return {
        responsive: true,
        //maintainAspectRatio: false,
        elements: {
            point: {
                radius: 1,
            },
        },
        interaction: {
            mode: "index",
            intersect: false,
        },
        plugins: {
            title: {
                display: true,
                text: title,
            },
        },
        scales: {
            x: {
                type: "time",
                time: {
                    unit: "day",
                },
            },
            y: {
                beginAtZero: true,
            },
        },
    };
};