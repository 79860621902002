<template>
  <!-- <div class="container">
    <console />
  </div> -->
  <router-view/>
  <!-- <console/> -->
</template>

<script>
import '@vuepic/vue-datepicker/dist/main.css'
import "primevue/resources/themes/saga-green/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import "primeflex/primeflex.css"; //flex
// import console from "./components/ConsolePage.vue";
export default {
  name: "App",
  components: {
    // console,
  },
};
</script>

<style lang="scss">

body {
  margin: 0;
  padding: 0;
}

</style>

