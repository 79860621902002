import PrimeVue from 'primevue/config';
import { createApp } from 'vue'
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import "@/plugins/fontawsome";
import App from './App.vue'
import router from './router';
import "chartjs-adapter-moment";
const app = createApp(App)
const $ = require('jquery');
window.$ = $;
app.use(PrimeVue, { ripple: true });
app.use(router);
app.component("font-awesome-icon", FontAwesomeIcon)
app.mount('#app')
