
import { createRouter, createWebHistory } from 'vue-router'  
import console from '../components/ConsolePage.vue';

import Ping from '../components/Ping.vue';

const routes = [
    {
        path: '/',
        name: 'console',
        component: console,//shsould be imported 
    },
    {
        path: '/history',
        name: 'history',
        component: () => import(/* webpackChunkName: "about" */ '../views/ConsolePage/History.vue'),
    },
    {
        path: '/ping',
        name: 'Ping',
        component: Ping,//shsould be imported 
    },
]

const router = createRouter({
    mode: 'history',
    history: createWebHistory(process.env.BASE_URL),
    routes
})
// createApp(App).use(router).mount('#app')

export default router;