<template>
  <!-- <div>
    <Chart :data="chartDatas" type="line" :options="chartOptions" />
  </div> -->
  <Dialog v-model:visible="alertDialog.displayDialog" :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
    :style="{ width: '50vw' }">
    <template #header>
      <i class="pi pi-exclamation-circle"></i>
      <h3>{{ alertDialog.dialogHeader }}</h3>
    </template>
    {{ alertDialog.dialogMessage }}
    <template #footer>
      <Button label="關閉" icon="pi pi-times" class="p-button-text" autofocus
        @click="alertDialog.displayDialog = false" />
    </template>
  </Dialog>
  <div class="container">
    <div class="flex align-items-center justify-content-center">
      <Datepicker v-model="selectDate" range @update:modelValue="handleDate" class="dtpicker col" :format="dpFormat" />
    </div>
    <div class="flex align-items-center justify-content-center">
      <i v-if="loading" class="pi pi-spin pi-spinner p-ml-3"></i><span v-if="loading">資料載入中</span>
    </div>

    <div class="flex align-items-center justify-content-center">
      <div style="max-width: 960px">
        <Galleria ref="galleria" :value="resImages" v-model:activeIndex="activeIndex" :showThumbnails="false"
          :showThumbnailNavigators="false" :showItemNavigators="true" :showItemNavigatorsOnHover="true" :circular="true"
          :transitionInterval="3000" :numVisible="1" containerStyle="" containerClass="max-w-screen"
          class="col custom-galleria">
          <template #header>
            <div class="custom-header">
              <span class="btn-icon" v-on:click="onGalleriaHeaderClick">
                {{ resImages[activeIndex].title }}
                <i class="pi pi-chevron-down"></i>
                薊馬數量:{{ getCountRes[resImages[activeIndex].timeIndex] }}
                 <Calendar id="day-calendar" :touchUI="true" :showIcon="true" @show="onShowHeaderCalendar"
                  @date-select="onHeaderCalendarSelect" :minDate="minDate" :maxDate="maxDate" />
              </span>
            </div>
          </template>
          <template #item="slotProps">
            <Image :src=slotProps.item.itemImageSrc :alt="slotProps.item.alt" imageStyle="width: 100%;">
            </Image>
          </template>
          <!-- <template #thumbnail>
            <div style="width: 100%" v-on:click="onClickThumbnailTitle">
              <h3 class="justify-content-center" style="color: white">

                薊馬數量:{{ getCountRes[resImages[activeIndex].timeIndex] }}
              </h3>
            </div>
          </template> -->
        </Galleria>
      </div>

    </div>

    <div class="flex align-items-center justify-content-center">
      <Chart type="bar" :data="accChart" :options="accOptions" class="chart" />
    </div>
    <div class="flex align-items-center justify-content-center">
      <Chart type="bar" :data="timeRelatedChart" :options="relatedOptions[sensorBox]" responsive class="chart" @select="chartSelectTimeDataset"/>
    </div>
  </div>
</template>

<script>
import Button from "primevue/button";
import Chart from "primevue/chart";
import Dialog from "primevue/dialog";
import Galleria from "primevue/galleria";
// import RadioButton from "primevue/radiobutton";
import Image from 'primevue/image';
import Calendar from 'primevue/calendar';

import { defineComponent, ref } from "vue";
import Datepicker from "@vuepic/vue-datepicker";
import axios from "axios";

import { getTwoAxisChartOptions, getSingleAxisChartOptions } from "./func/chart-functions.js"
import { formatDate, dpFormat, setLastDays } from "./func/date-functions.js"
import { getJWT, getAreaID, getGroupID, getAllSensor, getTimeIntervalData } from "./func/smartfarm-api.js"
import { triggerDialog } from "./func/Dialog.js"

export default defineComponent({
  name: "history",
  emits: ["emitPreset"],
  components: {
    Chart,
    // RadioButton,
    Datepicker,
    Galleria,
    Dialog,
    Button,
    Image,
    Calendar,
  },
  methods: {},
  setup(props, { emit }) {
    const dateOptions = ref([]);
    const dateDropdownSelect = ref("");
    const alertDialog = ref({
      displayDialog: false,
      dialogHeader: "",
      dialogMessage: "",
    });
    const token = ref("");
    const allSensor = ref([]);
    const sensorBox = ref(null);
    const resImages = ref([
      {
        itemImageSrc: "",
        thumbnailImageSrc: "",
        alt: "Result Images",
        title: "",
        timeIndex: 0,
      },
    ]);
    const timeData = ref({});
    const loading = ref(0);
    const activeIndex = ref(0);
    const testImage = ref("");
    const path_prefix = "https://thrips-ui.hsnl.tw/api/"; // for static folder(server env)
    //const path_prefix = "http://localhost:5000/"; // flask local host
    // const path_prefix = "http://192.168.50.143:8090/"; // flask local host
    const accChart = ref();
    const accOptions = ref();
    const selectPreset = ref({
      id: 0,
      account: "lianshing",
      preset: "LianShing_outside-1",
    });
    const presets = ref([
      {
        label: "lianshing",
        code: "US",
        items: [
          { label: "LianShing_outside-1", value: 0, account: "lianshing" },
          { label: "LianShing_outside-2", value: 1, account: "lianshing" },
          { label: "LianShing_outside-3", value: 2, account: "lianshing" },
          { label: "LianShing_outside-4", value: 3, account: "lianshing" },
          { label: "LianShing_outside-5", value: 4, account: "lianshing" },
          { label: "LianShing_outside-6", value: 5, account: "lianshing" },
          { label: "LianShing_greenhouse-1", value: 6, account: "lianshing" },
          { label: "LianShing_greenhouse-2", value: 7, account: "lianshing" },
          { label: "LianShing_greenhouse-3", value: 8, account: "lianshing" },
          { label: "LianShing_greenhouse-4", value: 9, account: "lianshing" },
          { label: "LianShing_greenhouse-5", value: 10, account: "lianshing" },
        ],
      },
    ]);
    // calling parent's function to change preset dropdown options in menubar
    emit("emitPreset", presets);

    const onPresetSelect = (target) => {
      selectPreset.value = {
        id: target.value,
        account: target.account,
        preset: target.label,
      };
      refreshInfo();
    };
    // const groupid = ref("");
    const selectDate = ref();
    const handleDate = (modelData) => {
      let date = new Date();
      let latestValidDate =
        date.getHours() >= 13 ? date : date.setDate(date.getDate() - 1);
      for (let i = 0; i < modelData.length; i++) {
        if (modelData[i] > latestValidDate) {
          triggerDialog(alertDialog, "所選時間超出範圍", formatDate(modelData[i]));
          return;
        }
      }
      selectDate.value = modelData;
      if (modelData.length == 2) {
        timeInterval.value = [];
        for (
          let i = new Date(modelData[0]);
          i <= modelData[1];
          i.setDate(i.getDate() + 1)
        ) {
          timeInterval.value.push(formatDate(i));
        }
        getPhoto();
        updateChartValue();
      }
    };
    const timeInterval = ref([]);
    timeInterval.value = setLastDays(14, selectDate);

    const thripQuantity = ref({
      type: "bar",
      label: "薊馬數量",
      backgroundColor: "#9ad0f5",
      data: [],
      yAxisID: "l1",
    });
    const getCountRes = ref([]);
    const relatedChart = ref({});
    const getCount = async () => {
      let path = path_prefix + "getCount";
      let begin_date = timeInterval.value[0].split("-");
      let end_date =
        timeInterval.value[timeInterval.value.length - 1].split("-");
      await axios
        .get(path, {
          params: {
            token: token.value,
            account: selectPreset.value.account,
            preset: selectPreset.value.preset,
            begin_year: begin_date[0], //"2022",
            begin_month: begin_date[1], //"04",
            begin_day: begin_date[2], //"22",
            end_year: end_date[0], //"2022",
            end_month: end_date[1], // "04",
            end_day: end_date[2], // "22",
          },
        })
        .then((results) => {
          console.log("received count", results.data);
          thripQuantity.value.data = [];
          getCountRes.value = Object.values(results.data);

          for (let d in results.data) {
            // getCountRes.value.push(results.data[d]);
            if (results.data[d] != undefined) {
              // thripQuantity.value.dates.push(d);
              thripQuantity.value.data.push({ x: d, y: results.data[d] });
            } else {
              //   thripQuantity.value.dates.push(d);
              if (thripQuantity.value.data.length != 0)
                // if there is missing value, use the lattest one.
                thripQuantity.value.data.push({
                  x: d,
                  y: thripQuantity.value.data[
                    thripQuantity.value.data.length - 1
                  ],
                });
              else thripQuantity.value.data.push({ x: d, y: 0 });
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    };
    const getPhoto = async () => {
      let path = path_prefix + "getPhoto";
      let begin_date = timeInterval.value[0].split("-");
      let end_date =
        timeInterval.value[timeInterval.value.length - 1].split("-");

      loading.value += 1;
      activeIndex.value = 0;
      await axios
        .get(path, {
          params: {
            token: token.value,
            account: selectPreset.value.account,
            preset: selectPreset.value.preset,
            begin_year: begin_date[0], //"2022",
            begin_month: begin_date[1], //"04",
            begin_day: begin_date[2], //"22",
            end_year: end_date[0], //"2022",
            end_month: end_date[1], // "04",
            end_day: end_date[2], // "22",
          },
        })
        .then((res) => {
          //  console.log("received photo", res.data);
          resImages.value = [];
          let missingImages = [];
          dateOptions.value = [];
          for (let i = 0; i < timeInterval.value.length; i++) {
            dateOptions.value.push({ name: timeInterval.value[i] });
            //  console.log(path_prefix + res.data[timeInterval.value[i]]);
            if (!res.data[timeInterval.value[i]].includes("no shot image")) {
              resImages.value.push({
                itemImageSrc: res.data[timeInterval.value[i]],
                thumbnailImageSrc:
                  res.data[timeInterval.value[i]],
                alt: "",
                title: timeInterval.value[i],
                timeIndex: i,
              });
            } else {
              missingImages.push(timeInterval.value[i]);
              console.log("No such photo files: " + timeInterval.value[i]);
            }
          }
          // if (missingImages.length != 0)
          //   triggerDialog(
          //    alertDialog,  
          //     timeInterval.value[0] +
          //       "~" +
          //       timeInterval.value[timeInterval.value.length - 1] +
          //       "之中,以下日期沒有辨識結果",
          //     missingImages
          //   );
        })
        .catch((error) => {
          console.error(error);
        });
      loading.value -= 1;
    };
    const refreshInfo = async () => {
      loading.value += 1;
      let { token: _token, uuid } = await getJWT(selectPreset.value.account);
      token.value = _token; 
      await getPhoto();
      
      let areaIds = await getAreaID(_token, uuid);
      let groupIds = await getGroupID(_token, Object.values(areaIds)[0]);
      let sensors = await getAllSensor(token, groupIds);
      await updateChartValue();
      allSensor.value = sensors;
      loading.value -= 1;
    };
    refreshInfo();
    const timeRelatedChart = ref({});
    const updateChartValue = () => {
      loading.value += 1;
      getCount().then(() => {
        let totalQuantity = [];
        let totalValue = 0;
        let accQuantity = [];
        totalValue = thripQuantity.value.data[0].y;
        totalQuantity.push({ x: thripQuantity.value.data[0].x, y: totalValue });
        accQuantity.push({ x: thripQuantity.value.data[0].x, y: totalValue });
        for (let i = 1; i < thripQuantity.value.data.length; i++) {
          let diff =
            thripQuantity.value.data[i].y - thripQuantity.value.data[i - 1].y;
          accQuantity.push({
            x: thripQuantity.value.data[i].x,
            y: diff > 0 ? diff : 0,
          });
          totalValue += accQuantity[i].y;
          totalQuantity.push({
            x: thripQuantity.value.data[i].x,
            y: totalValue,
          });
        }

        accChart.value = {
          datasets: [
            {
              type: "line",
              label: "薊馬每日增加量",
              data: accQuantity,
              backgroundColor: "#2e75cb",
              borderColor: "#2e75cb",
              borderWidth: 4,
            },
            {
              type: "bar",
              label: "薊馬累加總數量",
              backgroundColor: "#80ff80",
              data: totalQuantity,
            },
          ],
        };
        loading.value -= 1;
      });
      timeRelatedChart.value = {
        labels: timeInterval.value,
        datasets: [thripQuantity.value],
      };
      // let the overlap problem solved, line should not be block by the bar
      timeRelatedChart.value.datasets[
        timeRelatedChart.value.datasets.length - 1
      ].order = 1;
      console.log(`[sensors] ${allSensor.value}`);
      if(typeof allSensor.value === "string") return;
      for (let sensor in allSensor.value) {
        if (sensorBox.value == null) sensorBox.value = sensor;
        getRelatedChartData(allSensor.value[sensor], sensor);
      }
      
    };
    const relatedOptions = ref({});

    const getRelatedChartData = (s, index) => {
      getTimeIntervalData(s.sensorId, s.sensorType, timeInterval, token, selectPreset, timeData).then(() => {
        let res_data = [];
        for (let v in timeData.value[s.sensorType]) {
          res_data.push(timeData.value[s.sensorType][v]);
        }
        timeRelatedChart.value.datasets.push({
          type: "line",
          label: index,
          backgroundColor: "#ff6384",
          borderColor: "#ff6384",
          borderWidth: 4,
          fill: false,
          data: res_data,
          yAxisID: "r1",
        });
        if (sensorBox.value != index)
          timeRelatedChart.value.datasets[
            timeRelatedChart.value.datasets.length - 1
          ].hidden = true;
        timeRelatedChart.value.datasets[
          timeRelatedChart.value.datasets.length - 1
        ].order = 0;
        relatedOptions.value[index] = getTwoAxisChartOptions(
          "薊馬數量與感測器數據關聯圖"
        );
      });

    };


    const quantityOptions = ref({});
    accOptions.value = getSingleAxisChartOptions("薊馬累加量關聯圖");
    quantityOptions.value = getSingleAxisChartOptions("薊馬數量圖表");

    const changeChartData = (index) => {
      //timeRelatedChart.value[index].datasets[0]['hidden']=!timeRelatedChart.value[index].datasets[0]['hidden'];
      for (let i = 0; i < timeRelatedChart.value.datasets.length; i++) {
        const element = timeRelatedChart.value.datasets[i];
        if (element.label == index || element.label == "薊馬數量")
          timeRelatedChart.value.datasets[i].hidden = false;
        else timeRelatedChart.value.datasets[i].hidden = true;
      }
    };

    const onGalleriaHeaderClick = () => {
      var element = document.getElementsByClassName("p-datepicker-trigger")[0];
      element.click();
    };
    const minDate = ref(null);
    const maxDate = ref(null);
    const onShowHeaderCalendar = () => {
      minDate.value = new Date(resImages.value[0].title)
      maxDate.value = new Date(resImages.value[resImages.value.length - 1].title)
    };
   
    const onHeaderCalendarSelect = (e) => {
      let target = formatDate(e);
      for (let i = 0; i < resImages.value.length; i += 1) {
        if (target == resImages.value[i].title) {
          activeIndex.value = i;
        }
      }
    };

    const chartSelectTimeDataset = (e) => {
      console.log(e);
    };

    return {
      minDate,
      maxDate,
      alertDialog,
      relatedChart,
      relatedOptions,
      quantityOptions,
      selectDate,
      resImages,
      testImage,
      loading,
      getCountRes,
      allSensor,
      sensorBox,
      timeData,
      timeRelatedChart,
      activeIndex,
      timeInterval,
      selectPreset,
      // presetList,
      accChart,
      accOptions,
      dateOptions,
      dateDropdownSelect,
      dpFormat,
      handleDate,
      updateChartValue,
      getRelatedChartData,
      onPresetSelect,
      changeChartData,
      onShowHeaderCalendar,
      onHeaderCalendarSelect,
      onGalleriaHeaderClick,
      chartSelectTimeDataset,
    };
  },
});
</script>
<style>
.chart {
  width: 100%;
  max-width: 960px;
}

.dtpicker {
  max-width: 960px;
}

.myGalleria {
  max-width: 960px;
}

.max-w-screen {
  width: 100%;
}

.p-inputtext .p-component {
  visibility: hidden;
  width: 0;
  height: 0;
}
</style>

<style lang="scss" scoped>
::v-deep(.p-galleria .p-galleria-thumbnail-container) {
  padding: 0.1rem 0.1rem !important;
}

::v-deep(.p-galleria-header) {
  width: 100%;
  position: relative;
  height: 2rem;

  .custom-header {
    position: absolute;
    width: 100%;
    background: #000;
    height: 2rem;
    z-index: 999;
    color: #fff;
    text-align: center;
    font-size: 1.2em;

  }

  .btn-icon {
    cursor: pointer;
    border-radius: 3px;
    padding: 0 2px;
    transition: background .2s ease-in;

    &:hover {
      background: #0005;
    }
  }
}

::v-deep(.p-inputtext) {
  display: none !important;
}

::v-deep(.p-datepicker-trigger) {
  visibility: hidden !important;
  width: 0px !important;
  height: 0px !important;
}
</style>



<!-- <style lang="sass" scoped>
  $galleriaThumbnailContainerPadding: 0.1rem 0.1rem !default
  $galleriaThumbnailNavigatorHeight: 0.1rem !default

</style>> -->